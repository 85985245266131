import e1 from '../_assets/exposicao/1.jpeg'
import e2 from '../_assets/exposicao/2.jpeg'
import e3 from '../_assets/exposicao/3.jpeg'
import e4 from '../_assets/exposicao/4.jpeg'
import e5 from '../_assets/exposicao/5.jpeg'
import e6 from '../_assets/exposicao/6.jpeg'
import e7 from '../_assets/exposicao/7.jpeg'
import e8 from '../_assets/exposicao/8.jpeg'
import e9 from '../_assets/exposicao/9.jpeg'
import e10 from '../_assets/exposicao/10.jpeg'

export const exposicao = [
    { src: e1, width: 1, height: 1 },
    { src: e2, width: 1, height: 1 },
    { src: e3, width: 1, height: 1 },
    { src: e4, width: 1, height: 1 },
    { src: e5, width: 1, height: 1 },
    { src: e6, width: 1, height: 1 },
    { src: e7, width: 1, height: 1 },
    { src: e8, width: 1, height: 1 },
    { src: e9, width: 1, height: 1 },
    { src: e10, width: 1, height: 1 },
]