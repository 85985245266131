import PhotoGallery from '../../components/PhotoGalery';
import { oficinas } from '../../data/oficinas';
const Ofinica = () => {
	return (
		<>
			<div className='row p-5'>
				<PhotoGallery
					images={oficinas}
					title="Oficina de Livros Táteis"
					description="Fotos das oficinas de livros táteis realizadas"
				/>
			</div>
		</>
	);
};

export default Ofinica;
