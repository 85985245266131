import PhotoGallery from "../../components/PhotoGalery";
import { exposicao } from "../../data/exposicao";
const Exposicao = () => {
	return (
		<>
			<div className='row p-5'>
				<PhotoGallery
					images={exposicao}
					title="Exposição Braille de Ontem"
					description="Registro de momentos da inauguração da exposição Braille de Ontem"
				/>
			</div>
		</>
	);
};

export default Exposicao;
