import memoriasPostumas from "../_assets/obrasCapa/memorias-postumas-bras-cubas.png";
import pupilas from "../_assets/obrasCapa/as-pupilas.png";
import fundamentos from "../_assets/obrasCapa/findamentos-da-fisica.png";
import astronautas from "../_assets/obrasCapa/eram-deuses.png";
import luisBraille from "../_assets/obrasCapa/luis-braille.png";
import latim from "../_assets/obrasCapa/curso-latim.png";
import cobol from "../_assets/obrasCapa/cobol.png";
import coletanea from "../_assets/obrasCapa/coletanea.png";

const obras = [
	{
		id: 1,
		img: memoriasPostumas,
		titulo: "MEMÓRIAS PÓSTUMAS DE BRÁS CUBAS",
		autor: "Machado de Assis",
		subtitulo: "Transcrição autorizada pela Editora Cultrix",
		arquivo: "https://drive.google.com/drive/folders/1crBgbNIPCNofhxZrJWisjxRtMQmcni2G",
	},
	{
		id: 2,
		img: pupilas,
		titulo: "AS PUPILAS DO SENHOR REITOR",
		autor: "Júlio Dinis",
		arquivo: "https://drive.google.com/drive/folders/11cPZNEtcfCpRnwAVa4t3GLJTYV_-_eAL",
	},
	{
		id: 3,
		img: fundamentos,
		titulo: "FUNDAMENTOS DA FÍSICA",
		subtitulo: "Termologia, Óptica Geométrica e Ondas",
		autor: "Vários Autores",
		arquivo: "https://drive.google.com/drive/folders/1NSWbZay-JDYk69gXtV6ARTLFWjIHaed7"
	},
	{
		id: 4,
		img: coletanea,
		titulo: "COLEÇÃO LIVROS EM BRAILLE",
		subtitulo:
			"A vaca no telhado | A vaca Rebeca | Confusão no Jardim | O ratinho espertalhão",
		autor: "Autores diversos",
		arquivo: "https://drive.google.com/drive/folders/1g__bkEAaHU8E1XuimDupSasN3ir5G42y",
	},
	{
		id: 5,
		img: cobol,
		titulo: "CURSO DE PROGRAMAÇÃO PARA DEFICIENTES VISUAIS: COBOL",
		subtitulo: "Volume único",
		autor: "Salamah, Carmen Aparecida",
		arquivo: "https://drive.google.com/drive/folders/1_9Y2ghVzGdji2gDqRoudEtC3gSU2sjU4"
	},
	{
		id: 6,
		img: astronautas,
		titulo: "ERAM OS DEUSES ASTRONAUTAS?",
		subtitulo: "Volume 1 e Volume 2",
		autor: "Erich Von Dânken",
		arquivo: "https://drive.google.com/drive/folders/13X1OCf0CptoYcilYIQOlk-EjR8Dt7DR-",
	},
	{
		id: 7,
		img: luisBraille,
		titulo: "LUÍS BRAILLE - JANELAS PARA OS CEGOS",
		subtitulo: "",
		autor: "J. Alvin Kugelmass",
		arquivo: ""
	},
	{
		id: 8,
		img: latim,
		titulo: "CURSO DE LATIM",
		subtitulo: "Volume Único",
		autor: "",
		arquivo: "https://drive.google.com/drive/folders/1HcMcHamKZdxhSrbeDHPTVVnqgkzg620V",
	},	
];
export { obras };
