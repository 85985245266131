import of1 from '../_assets/oficina_livros_tateis/1.jpeg';
import of2 from '../_assets/oficina_livros_tateis/2.jpeg';
import of3 from '../_assets/oficina_livros_tateis/3.jpeg';
import of4 from '../_assets/oficina_livros_tateis/4.jpeg';
import of5 from '../_assets/oficina_livros_tateis/5.jpeg';
import of6 from '../_assets/oficina_livros_tateis/6.jpeg';
import of7 from '../_assets/oficina_livros_tateis/7.jpeg';
import of8 from '../_assets/oficina_livros_tateis/8.jpeg';
import of9 from '../_assets/oficina_livros_tateis/9.jpeg';
import of10 from '../_assets/oficina_livros_tateis/10.jpeg';
import of11 from '../_assets/oficina_livros_tateis/11.jpeg';
import of12 from '../_assets/oficina_livros_tateis/12.jpeg';
import of13 from '../_assets/oficina_livros_tateis/13.jpeg';
import of14 from '../_assets/oficina_livros_tateis/14.jpeg';
import of15 from '../_assets/oficina_livros_tateis/15.jpeg';
import of16 from '../_assets/oficina_livros_tateis/16.jpeg';
import of17 from '../_assets/oficina_livros_tateis/17.jpeg';
import of18 from '../_assets/oficina_livros_tateis/18.jpeg';
import of19 from '../_assets/oficina_livros_tateis/19.jpeg';
import of20 from '../_assets/oficina_livros_tateis/20.jpeg';
import of21 from '../_assets/oficina_livros_tateis/21.jpeg';
import of22 from '../_assets/oficina_livros_tateis/22.jpeg';
import of23 from '../_assets/oficina_livros_tateis/23.jpeg';
import of24 from '../_assets/oficina_livros_tateis/24.jpeg';
import of25 from '../_assets/oficina_livros_tateis/25.jpeg';
import of26 from '../_assets/oficina_livros_tateis/26.jpeg';
import of27 from '../_assets/oficina_livros_tateis/27.jpeg';
import of28 from '../_assets/oficina_livros_tateis/28.jpeg';
import of29 from '../_assets/oficina_livros_tateis/29.jpeg';
import of30 from '../_assets/oficina_livros_tateis/30.jpeg';
import of31 from '../_assets/oficina_livros_tateis/31.jpeg';
import of32 from '../_assets/oficina_livros_tateis/32.jpeg';
import of33 from '../_assets/oficina_livros_tateis/33.jpeg';
import of34 from '../_assets/oficina_livros_tateis/34.jpeg';
import of35 from '../_assets/oficina_livros_tateis/35.jpeg';
import of36 from '../_assets/oficina_livros_tateis/36.jpeg';
import of37 from '../_assets/oficina_livros_tateis/37.jpeg';
import of38 from '../_assets/oficina_livros_tateis/38.jpeg';
import of39 from '../_assets/oficina_livros_tateis/39.jpeg';
import of40 from '../_assets/oficina_livros_tateis/40.jpeg';
import of41 from '../_assets/oficina_livros_tateis/41.jpeg';
import of42 from '../_assets/oficina_livros_tateis/42.jpeg';

export const oficinas = [
  { src: of1, width: 1, height: 1 },
  { src: of2, width: 1, height: 1 },
  { src: of3, width: 1, height: 1 },
  { src: of4, width: 1, height: 1 },
  { src: of5, width: 1, height: 1 },
  { src: of6, width: 1, height: 1 },
  { src: of7, width: 1, height: 1 },
  { src: of8, width: 1, height: 1 },
  { src: of9, width: 1, height: 1 },
  { src: of10, width: 1, height: 1 },
  { src: of11, width: 1, height: 1 },
  { src: of12, width: 1, height: 1 },
  { src: of13, width: 1, height: 1 },
  { src: of14, width: 1, height: 1 },
  { src: of15, width: 1, height: 1 },
  { src: of16, width: 1, height: 1 },
  { src: of17, width: 1, height: 1 },
  { src: of18, width: 1, height: 1 },
  { src: of19, width: 1, height: 1 },
  { src: of20, width: 1, height: 1 },
  { src: of21, width: 1, height: 1 },
  { src: of22, width: 1, height: 1 },
  { src: of23, width: 1, height: 1 },
  { src: of24, width: 1, height: 1 },
  { src: of25, width: 1, height: 1 },
  { src: of26, width: 1, height: 1 },
  { src: of27, width: 1, height: 1 },
  { src: of28, width: 1, height: 1 },
  { src: of29, width: 1, height: 1 },
  { src: of30, width: 1, height: 1 },
  { src: of31, width: 1, height: 1 },
  { src: of32, width: 1, height: 1 },
  { src: of33, width: 1, height: 1 },
  { src: of34, width: 1, height: 1 },
  { src: of35, width: 1, height: 1 },
  { src: of36, width: 1, height: 1 },
  { src: of37, width: 1, height: 1 },
  { src: of38, width: 1, height: 1 },
  { src: of39, width: 1, height: 1 },
  { src: of40, width: 1, height: 1 },
  { src: of41, width: 1, height: 1 },
  { src: of42, width: 1, height: 1 }
];